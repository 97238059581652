#cardClientes { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {

  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;

  margin: 0;
  padding: 0;
  z-index:-1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://turixcam-images.b-cdn.net/Recursos%20WEB/Portadas/V2/Por8c.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}



.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
